import Service from "./Service";
const baseurl = '/api';
export default {
    verEtnia(id){
        let url = `${baseurl}/etnia/ver/`;
        return Service.get(url,id);
    },
    dataSourceEtnia(search = ''){
        let url = `${baseurl}/etnia/data-source-etnia${search}`;
        return Service.get(url);
    },
    verProfesion(id){
        let url = `${baseurl}/profesion/ver/`;
        return Service.get(url,id);
    },
    dataSourceProfesion(search = ''){
        let url = `${baseurl}/profesion/data-source-profesion${search}`;
        return Service.get(url);
    },
    verEstadoCivil(id){
        let url = `${baseurl}/estadoCivil/ver/`;
        return Service.get(url,id);
    },
    dataSourceEstadoCivil(search = ''){
        let url = `${baseurl}/estadoCivil/data-source-estadoCivil${search}`;
        return Service.get(url);
    },
    verMunicipio(id){
        let url = `${baseurl}/municipio/ver/`;
        return Service.get(url,id);
    },
    dataSourceMunicipio(search = ''){
        let url = `${baseurl}/municipio/data-source-municipio${search}`;
        return Service.get(url);
    },
    verZona(id){
        let url = `${baseurl}/zona/ver/`;
        return Service.get(url,id);
    },
    dataSourceZona(search = ''){
        let url = `${baseurl}/zona/data-source-zona${search}`;
        return Service.get(url);
    }
}