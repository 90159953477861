<template>
  <v-select
      label="text"
      :options="profesiones"
      :reduce="profesion => mode === 'object' ? profesion : profesion.id"
      @search="debounceSearch"
      @input="$emit('input', $event)"
      v-model="selected">
    <template #no-options="{ search }">
      No se encontraron resultados para <strong>{{ search }}</strong>
    </template>
    <template #search="{ attributes, events }">
      <input class="vs__search" v-bind="attributes" v-on="events"/>
    </template>
  </v-select>
</template>

<script>

import {isEmpty, debounce} from 'lodash';
import VSelect from 'vue-select';
import dataSourceService from '../../services/dataSourceService';

export default {
  props: ["value", "mode", "validator"],
  components :{VSelect},
  data() {
    return {
      data_sources_profesion: {
        profesion: "/api/profesion/data-source-profesion",
      },
      profesiones: [],
      selected: this.value,
      search: "",
    };
  },
  created() {
    this.debounceSearch = debounce(this.buscarProfesion, 300);
  },
  watch: {
    value: async function (newValue) {

      if (this.mode === "object") {
        if (isEmpty(newValue) || newValue === undefined || newValue === null) {
          this.selected = this.mode === "object" ? {} : "";
          return;
        }
      } else {
        if (newValue === "" || newValue === undefined || newValue === null) {
          this.selected = this.mode === "object" ? {} : "";
          return;
        }
      }

      let id = this.mode === "object" ? newValue.id : newValue;

      if (isEmpty(this.profesiones.find(x => x.id === id))) {

        const response =await dataSourceService.verProfesion(id);
        

          const p = isEmpty(response.data) ? {} : { id: response.data.id, text: `${response.data.codigo} ${response.data.nombre}`};

          this.profesiones.push({...p});

          this.selected = {...p};

          this.$emit("input", newValue);
      }
    }
  },
  methods: {
    async buscarProfesion(search, loading) {
      if (search === "") {
        return;
      }
      try {
        loading(true);
        const response = await dataSourceService.dataSourceProfesion(`?s=${escape(search)}`);
        this.profesiones = response.data.results;
        loading(false);
      } catch (error) {
        loading(false);
        alert("Ocurrio un error, por favor intente nuevamente");
      }
    },
    setChange(value) {
      this.$emit("update-value", value);
      this.$emit('input', value);
    },
  },
  computed: {
    classObject: function () {
      if (this.validator === undefined || this.validator === null) {
        return "";
      }
      return this.validator.$error ? "v-select-error is-invalid" : "is-valid";
    },
  },
}
</script>
