<template>
  <v-select
    label="text"
    :options="zonas"
    :reduce="(zona) => (mode === 'object' ? zona : zona.id)"
    @search="debounceSearch"
    @input="setChange"
    v-model="selected"
  >
    <template #no-options="{ search }">
      No se encontraron resultados para <strong>{{ search }}</strong>
    </template>
    <template #search="{ attributes, events }">
      <input class="vs__search" v-bind="attributes" v-on="events" />
    </template>
  </v-select>
</template>

<script>
import {debounce, isEmpty} from 'lodash';
import VSelect from 'vue-select';
import dataSourceService from "../../services/dataSourceService";

export default {
  props: ["value", "mode", "validator"],
  components : {VSelect},
  data() {
    return {
      data_sources_zona: {
        zona: "/api/zona/data-source-zona",
      },

      zonas: [],
      selected: this.value,
      search: "",
    };
  },
  created() {
    this.debounceSearch = debounce(this.buscarZona, 300);
  },
  watch: {

 value: async function (value) {
      if (this.mode === "object") {
        if (isEmpty(value) || value === undefined || value === null) {
          this.selected = this.mode === "object" ? {} : "";
          return;
        }
      } else {
        if (value === "" || value === undefined || value === null) {
          this.selected = this.mode === "object" ? {} : "";
          return;
        }
      }

      let id = this.mode === "object" ? value.id : value;

      if (isEmpty(this.zonas.find((x) => x.id === id))) {

        const response =await dataSourceService.verZona(id);
       

        
          this.zonas.push({
            id: response.data.id,
            text: `${response.data.codigo} ${response.data.descripcion}`,
          });

          this.selected = {
            id: response.data.id,
            text: `${response.data.codigo} ${response.data.descripcion}`,
          };
          this.$emit("input", value);
      }
    },


  },

  methods: {
    async buscarZona(search, loading) {
      try {
              loading(true);
              const response = await dataSourceService.dataSourceZona(`?s=${escape(search)}`);
              this.zonas = response.data.results;
              loading(false);
            } catch (error) {
              loading(false);
              alert("Ocurrio un error, por favor intente nuevamente");
            }
    },
    setChange(value) {
      this.$emit("update-value", value);
    },
    
  },
computed: {
    classObject: function () {
      if (this.validator === undefined || this.validator === null) {
        return "";
      }
      return this.validator.$error ? "v-select-error is-invalid" : "is-valid";
    },
  },


};
</script>
<style scoped>
</style>
