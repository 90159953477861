<template>
  <v-select
      :class="classObject"
      label="text"
      :options="municipios"
      :reduce="(municipio) => (mode === 'object' ? municipio : municipio.id)"
      @search="debounceSearch"
      @input="setChange"
      v-model="selected"
  >
    <template #no-options="{ search }">
      No se encontraron resultados para <strong>{{ search }}</strong>
    </template>
    <template #search="{ attributes, events }">
      <input class="vs__search" v-bind="attributes" v-on="events" />
    </template>
  </v-select>
</template>
<script>

import {debounce, isEmpty} from 'lodash';
import VSelect from 'vue-select';
import dataSourceService from "../../services/dataSourceService";
export default {
  props: ["value", "mode", "validator"],
  components : {VSelect},
  data() {
    return {
      data_sources_municipio: {
        municipio: "/api/municipio/data-source-municipio",
      },
      municipios: [],
      selected: this.value,
      search: "",
    };
  },
  created() {
    this.debounceSearch = debounce(this.buscarMunicipio, 300);
  },
  watch: {
    value:  async function (value) {
      if (this.mode === "object") {
        if (isEmpty(value) || value === undefined || value === null) {
          this.selected = this.mode === "object" ? {} : "";
          return;
        }
      } else {
        if (value === "" || value === undefined || value === null) {
          this.selected = this.mode === "object" ? {} : "";
          return;
        }
      }

      let id = this.mode === "object" ? value.id : value;

      if (isEmpty(this.municipios.find((x) => x.id === id))) {
        const response =await dataSourceService.verMunicipio(id);

       

          this.municipios.push({
            id: response.data.id,
            text: `${response.data.codigo} ${response.data.nombre}`,
          });

          this.selected = {
            id: response.data.id,
            text: `${response.data.codigo} ${response.data.nombre}`,
          };

          this.$emit("input", value);

      }

    }
  },

  methods: {
    async buscarMunicipio(search, loading) {
      if(search === '')
      {
        return;
      }
       try {
        loading(true);
        const response = await dataSourceService.dataSourceMunicipio(`?s=${escape(search)}`);
        this.municipios = response.data.results;
        loading(false);
      } catch (error) {
        loading(false);
        alert("Ocurrio un error, por favor intente nuevamente");
      }
    },
    setChange(value) {
      this.$emit("update-value", value);
      this.$emit('input', value);
    },
  },
  computed : {
    classObject : function (){

      if(this.validator === undefined || this.validator === null){
        return '';
      }

      return this.validator.$error ? 'v-select-error is-invalid' : 'is-valid';
    }
  }
}
</script>

