<template>
  <v-select
    label="text"
    :options="etnias"
    :reduce="(etnia) => (mode === 'object' ? etnia : etnia.id)"
    @search="debounceSearch"
    @input="setChange"
    v-model="selected"
  >
    <template #no-options="{ search }">
      No se encontraron resultados para <strong>{{ search }}</strong>
    </template>
    <template #search="{ attributes, events }">
      <input class="vs__search" v-bind="attributes" v-on="events" />
    </template>
  </v-select>
</template>

<script>

import {debounce, isEmpty} from 'lodash';
import VSelect from 'vue-select';
import dataSourceService from "../../services/dataSourceService";
export default {
  props: ["value", "mode","validator"],
  components : {VSelect},
  data() {
    return {
      data_sources_etnia: {
        etnia: "/api/etnia/data-source-etnia",
      },

      etnias: [],
      selected: this.value,
      search: "",
    };
  },
  created() {
    this.debounceSearch = debounce(this.buscarEtnia, 300);
  },
  watch: {
  value: async function (value) {
      if (this.mode === "object") {
        if (isEmpty(value) || value === undefined || value === null) {
          this.selected = this.mode === "object" ? {} : "";
          return;
        }
      } else {
        if (value === "" || value === undefined || value === null) {
          this.selected = this.mode === "object" ? {} : "";
          return;
        }
      }

      let id = this.mode === "object" ? value.id : value;

      if (isEmpty(this.etnias.find((x) => x.id === id))) {

        const response =await dataSourceService.verEtnia(id);
        

        
          this.etnias.push({
            id: response.data.id,
            text: `${response.data.id} ${response.data.nombre}`,
          });

          this.selected = {
            id: response.data.id,
           text: `${response.data.id} ${response.data.nombre}`,
          };
          this.$emit("input", value);
      }
    },


  },

  methods: {
    async buscarEtnia(search, loading) {

      try {
        loading(true);
        const response = await dataSourceService.dataSourceEtnia(`?s=${escape(search)}`);
        this.etnias = response.data.results;
        loading(false);
      } catch (error) {
        loading(false);
        alert("Ocurrio un error, por favor intente nuevamente");
      }
      
    },
    setChange(value) {
      this.$emit("update-value", value);
    },
  },
 computed: {
    classObject: function () {
      if (this.validator === undefined || this.validator === null) {
        return "";
      }
      return this.validator.$error ? "v-select-error is-invalid" : "is-valid";
    },
  },



};
</script>
<style scoped>
</style>
